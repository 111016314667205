import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import { graphql, useStaticQuery } from 'gatsby';
import { Organization, Shipment } from 'src/application/entities/shipment';
import { useTranslation } from 'src/views/misc/localization';
import {
  Address,
  AddressWrapper,
  ArrowRight,
  Box,
  CustomRichText,
  DetailLink,
  DetailLinkWrapper,
  Flex,
  Logo,
  LogoWrapper,
  NotFoundText,
  TrackingNumber,
} from './shared.styles';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type ResultsProps = {
  parcelNotFoundSuggestionText: string;
  trackingNumber: string;
  results: Shipment[]
}

type QueryResult = {
  sdLogo?: {
    publicURL?: string;
  }
  secLogo?: {
    publicURL?: string;
  }
  smLogo?: {
    publicURL?: string;
  }
  sfLogo?: {
    publicURL?: string;
  }
}

const SingleNumberResults: React.FC<ResultsProps> = ({
  parcelNotFoundSuggestionText,
  trackingNumber,
  results,
}) => {
  const { t } = useTranslation();
  const logos = useStaticQuery<QueryResult>(graphql`
    query {
      sdLogo: file(
        relativePath: {eq: "sd-logo.svg"}
      ) {
        publicURL
      }
      secLogo: file(
        relativePath: {eq: "sec-logo.svg"}
      ) {
        publicURL
      }
      smLogo: file(
        relativePath: {eq: "sm-logo.svg"}
      ) {
        publicURL
      }
      sfLogo: file(
        relativePath: {eq: "sf-logo.svg"}
      ) {
        publicURL
      }
    }
  `);

  const getLinkText = (org: Organization): string => {
    let company: string;

    switch (org) {
      case 'SF':
        company = 'ShipaFreight';
        break;
      case 'SD':
        company = 'ShipaDelivery';
        break;
      case 'SM':
        company = 'ShipaMall';
        break;
      case 'SECMTS':
        company = 'Mintsoft';
        break;
      case 'SEC':
        company = 'ShipaEcommerce';
        break;
      default:
        company = '';
        break;
    }
    return `trackItOn${ company }`;
  };
  const getLogoProps = (org: Organization) => {
    switch (org) {
      case 'SF':
        return { src: logos.sfLogo?.publicURL, alt: 'Shipa Freight logo' };
      case 'SD':
        return { src: logos.sdLogo?.publicURL, alt: 'Shipa Delivery logo' };
      case 'SM':
        return { src: logos.smLogo?.publicURL, alt: 'Shipa Mall logo' };
      case 'SEC':
        return { src: logos.secLogo?.publicURL, alt: 'Shipa Ecommerce logo' };
      default:
        return '';
    }
  };
  return (
    <Box data-test="track-shipment-results-single">
      <TrackingNumber>{trackingNumber}</TrackingNumber>
      {results.length > 0 ? (
        results.map((shipment) => (
          <Flex>
            <LogoWrapper>
              <Logo
                {...getLogoProps(shipment.unit) /* eslint-disable-line react/jsx-props-no-spreading, max-len */}
                isShipaMall={shipment.unit === 'SM'}
                loading="lazy"
              />
            </LogoWrapper>
            <AddressWrapper>
              {shipment.from ? (
                <Address>
                  <span>
                    {t('from')}
                    :
                  </span>
                  {'  '}
                  {shipment.from}
                </Address>
              ) : <div />}
              {shipment.to ? (
                <Address>
                  <span>
                    {t('to')}
                    :
                  </span>
                  {'  '}
                  {shipment.to}
                </Address>
              ) : <div />}
            </AddressWrapper>
            <DetailLinkWrapper>
              <DetailLink
                dataTest="track-shipment-results-link"
                href={shipment.redirectTo}
              >
                {t(getLinkText(shipment.unit))}
                <ArrowRight />
              </DetailLink>
            </DetailLinkWrapper>
          </Flex>
        ))
      ) : (
        <NotFoundText data-test="track-shipment-results-not-found">
          <CustomRichText>
            <ReactMarkdown
              options={gloabalReactMdOptions}
            >
              {parcelNotFoundSuggestionText
                .replace('{{trackingNumber}}', trackingNumber)}
            </ReactMarkdown>
          </CustomRichText>
        </NotFoundText>
      )}
    </Box>
  );
};

export default SingleNumberResults;
